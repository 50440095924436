import React from "react";
import "./styles.css";
import FadeIn from "react-fade-in";

export default function Current() {
  return (
    <FadeIn>
      <div className="homeContainer">
        <h1 className="homeTitle">#telugu_freedom_fighters</h1>
        <div className="contact freedom_table">


<table className="text-left">
        <tr>
        <th className="p-r-20">Sl No</th>
            <th>Name</th>
            <th className="wid-20"></th>
            <th>Period</th>
            <th className="wid-20"></th>
            <th>Status</th>
        </tr>
        <tr>
          <td>1</td>
            <td>Sri Vasireddy Venkatadri Nayudu</td>
            <td></td>
            <td>1783 - 1816</td>
            <td></td>
            <td>In-progress</td>
        </tr>

        <tr>
        <td>2</td>
            <td>Sri Uyyalawada Narasimha Reddy</td>
            <td></td>
            <td>24 Nov 1806 - 23 Feb 1847</td>
        </tr>

        <tr>
        <td>3</td>
            <td>Sri Kandukuri Veeresalingam</td>
            <td></td>
            <td>16 Apr 1848 - 27 May 1919</td>
        </tr>

        <tr>
        <td>4</td>
            <td>Sri Nyapathi Subba Rao Pantulu</td>
            <td></td>
            <td>14 Jan 1856 - 15 Jan 1941</td>
        </tr>

        <tr>
        <td>5</td>
            <td>Sri Gurajada Apparao</td>
            <td></td>
            <td>21 Sep 1862 - 30 Nov 1915</td>
        </tr>

        <tr>
        <td>6</td>
            <td>Sri Kanneganti Hanumanthu</td>
            <td></td>
            <td>1870 - 1922</td>
        </tr>

        <tr>
        <td>7</td>
            <td>Sri Tanguturi Prakasam Pantulu</td>
            <td></td>
            <td>23 Aug 1872 - 20 May 1957</td>
        </tr>

        <tr>
        <td>8</td>
            <td>Sri Pingali Venkayya</td>
            <td></td>
            <td>2 Aug 1876 - 4 Jul 1963</td>
        </tr>

        <tr>
        <td>9</td>
            <td>Smt. Sarojini Naidu</td>
            <td></td>
            <td>13 Feb 1879 - 2 Mar 1949</td>
        </tr>

        <tr>
        <td>10</td>
            <td>Sri Bhogaraju Pattabhi Sitaramayya</td>
            <td></td>
            <td>24 Nov 1880 - 17 Dec 1959</td>
        </tr>

        <tr>
        <td>11</td>
            <td>Sri Cattamanchi Ramalinga Reddy</td>
            <td></td>
            <td>10 Dec 1880 - 24 Feb 1951</td>
        </tr>

        <tr>
        <td>12</td>
            <td>Sri Tripuraneni Ramaswamy</td>
            <td></td>
            <td>1887 - 1943</td>
        </tr>

        <tr>
        <td>13</td>
            <td>Sri P. Varadarajulu Naidu</td>
            <td></td>
            <td>4 Jun 1887 - 23 Jul 1957</td>
        </tr>

        <tr>
        <td>14</td>
            <td>Sri Duggirala Gopalakrishnayya</td>
            <td></td>
            <td>2 Jun 1889 - 10 Jun 1928</td>
        </tr>

        <tr>
        <td>15</td>
            <td>Sri Gurram Jashuva</td>
            <td></td>
            <td>28 Sep 1895 - 24 Jul 1971</td>
        </tr>

        <tr>
        <td>16</td>
            <td>Sri Alluri Sitarama Raju</td>
            <td></td>
            <td>4 Jul 1897 / 1898 - 7 May 1924</td>
        </tr>

        <tr>
        <td>17</td>
            <td>Sri N. G. Ranga</td>
            <td></td>
            <td>7 Nov 1900 - 9 Jun 1995</td>
        </tr>

        <tr>
        <td>18</td>
            <td>Sri Komaram Bheem</td>
            <td></td>
            <td>1901-1940</td>
        </tr>

        <tr>
        <td>19</td>
            <td>Sri Potti Sreeramulu</td>
            <td></td>
            <td>16 Mar 1901 - 15 Dec 1952</td>
        </tr>

        <tr>
        <td>20</td>
            <td>Sri Goparaju Ramachandra Rao</td>
            <td></td>
            <td>15 Nov 1902 - 26 Jul 1975</td>
        </tr>

        <tr>
        <td>21</td>
            <td>Sri Vedire Ramachandra Reddy</td>
            <td></td>
            <td>1905 - 1986</td>
        </tr>

        <tr>
        <td>22</td>
            <td>Sri Vavilala Gopalakrishnayya</td>
            <td></td>
            <td>1906 - 29 Apr 2003</td>
        </tr>

        <tr>
        <td>23</td>
            <td>Sri Jananayaka Chowdary Satyanarayana</td>
            <td></td>
            <td>13 Jul 1908 - 15 Jul 1981</td>
        </tr>

        <tr>
        <td>24</td>
            <td>Smt. Durgabai Deshmukh</td>
            <td></td>
            <td>15 Jul 1909 - 9 May 1981</td>
        </tr>

        <tr>
        <td>25</td>
            <td>Sri Sardar Gouthu Latchanna</td>
            <td></td>
            <td>16 Aug 1909 - 19 Apr 2006</td>
        </tr>

        <tr>
        <td>26</td>
            <td>Sri Arutla Ramchandra Reddy</td>
            <td></td>
            <td>1909 - 1952</td>
        </tr>

        <tr>
        <td>27</td>
            <td>Sri Anabheri Prabhakar Rao</td>
            <td></td>
            <td>15 Aug 1910 - 14 Mar 1948</td>
        </tr>

        <tr>
        <td>28</td>
            <td>Sri Tripuraneni Gopichand</td>
            <td></td>
            <td>8 Sep 1910 - 2 Nov 1962</td>
        </tr>

        <tr>
        <td>29</td>
            <td>Sri Kaloji Narayana Rao</td>
            <td></td>
            <td>9 Sep 1914 - 13 Nov 2002</td>
        </tr>

        <tr>
        <td>30</td>
            <td>Sri Pydimarri Venkata Subba Rao</td>
            <td></td>
            <td>10 Jun 1916 - 13 Aug 1988</td>
        </tr>

  </table>

        </div>
      </div>
    </FadeIn>
  );
}
