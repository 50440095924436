import React, { useState, useCallback } from "react";
import "./styles.css";
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Lightbox CSS
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(props.value );
  if(props.value==0){
    photos =monuments ;
  }
  if(props.value==1){
    photos = portrai;
  }

  if(props.value==2){
    photos = objects;
  }

  
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function About() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [value, setValue] = React.useState(0);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <FadeIn>
      <div className="homeContainer">
        <h1 className="galleryTitle">My Realistic Artworks....</h1>



        <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Monuments" {...a11yProps(0)} />
          <Tab label="Portraits" {...a11yProps(1)} />
          <Tab label="Objects" {...a11yProps(0)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
      <Gallery photos={monuments} onClick={openLightbox} />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Gallery photos={portrai} onClick={openLightbox} />
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Gallery photos={objects} onClick={openLightbox} />
      </TabPanel>
    

        
        {viewerIsOpen && (
          <Lightbox
            mainSrc={photos[currentImage].src}
            nextSrc={photos[(currentImage + 1) % photos.length].src}
            prevSrc={photos[(currentImage + photos.length - 1) % photos.length].src}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setCurrentImage((currentImage + photos.length - 1) % photos.length)
            }
            onMoveNextRequest={() =>
              setCurrentImage((currentImage + 1) % photos.length)
            }
          />
        )}
      </div>
    </FadeIn>
  );
}

let photos = [
  { src: "/Images/m_1.jpg", width: 3, height: 6 },
  { src: "/Images/m_2.jpg", width: 4, height: 9 },
  { src: "/Images/m_3.jpg", width: 6, height: 4 },
  { src: "/Images/m_4.jpg", width: 7, height: 4 },
  { src: "/Images/m_5.jpg", width: 7, height: 3 },
  { src: "/Images/m_6.jpg", width: 7, height: 3 },
  { src: "/Images/m_7.jpg", width: 5, height: 3 },
  { src: "/Images/m_8.jpg", width: 6, height: 3 },
  { src: "/Images/m_9.jpg", width: 6, height: 3 },
  { src: "/Images/m_10.jpg", width: 7, height: 4 },
  { src: "/Images/m_11.jpg", width: 5, height: 3 },
  { src: "/Images/m_12.jpg", width: 5, height: 3 },
  { src: "/Images/m_13.jpg", width: 6, height: 4 },
  { src: "/Images/m_14.jpg", width: 7, height: 4 },
  { src: "/Images/m_15.jpg", width: 5, height: 4 },
  { src: "/Images/m_16.jpg", width: 5, height: 3 },
  { src: "/Images/m_17.jpg", width: 4, height: 5 },
  { src: "/Images/m_18.jpg", width: 5, height: 3 },
  { src: "/Images/m_19.jpg", width: 5, height: 3 },
  { src: "/Images/m_20.jpg", width: 7, height: 4 }
];

const monuments = [
  { src: "/Images/m_1.jpg", width: 3, height: 6 },
  { src: "/Images/m_2.jpg", width: 4, height: 9 },
  { src: "/Images/m_3.jpg", width: 6, height: 4 },
  { src: "/Images/m_4.jpg", width: 7, height: 4 },
  { src: "/Images/m_5.jpg", width: 7, height: 3 },
  { src: "/Images/m_6.jpg", width: 7, height: 3 },
  { src: "/Images/m_7.jpg", width: 5, height: 3 },
  { src: "/Images/m_8.jpg", width: 6, height: 3 },
  { src: "/Images/m_9.jpg", width: 6, height: 3 },
  { src: "/Images/m_10.jpg", width: 7, height: 4 },
  { src: "/Images/m_11.jpg", width: 5, height: 3 },
  { src: "/Images/m_12.jpg", width: 5, height: 3 },
  { src: "/Images/m_13.jpg", width: 6, height: 4 },
  { src: "/Images/m_14.jpg", width: 7, height: 4 },
  { src: "/Images/m_15.jpg", width: 5, height: 4 },
  { src: "/Images/m_16.jpg", width: 5, height: 3 },
  { src: "/Images/m_17.jpg", width: 4, height: 5 },
  { src: "/Images/m_18.jpg", width: 5, height: 3 },
  { src: "/Images/m_19.jpg", width: 5, height: 3 },
  { src: "/Images/m_20.jpg", width: 7, height: 4 }
];

const portrai = [
  { src: "/Images/p_0.jpg", width: 7, height: 10 },
  { src: "/Images/p_1.jpg", width: 6, height: 7 },
  { src: "/Images/p_2.jpg", width: 7, height: 9 },
  { src: "/Images/p_3.jpg", width: 6, height: 7 },
  { src: "/Images/p_4.jpg", width: 8, height: 9 },
  { src: "/Images/p_5.jpg", width: 6, height: 8 },
  { src: "/Images/p_6.jpg", width: 4, height: 3 },
  { src: "/Images/p_7.jpg", width: 6, height: 9 },
  { src: "/Images/p_8.jpg", width: 5, height: 3 },
  { src: "/Images/p_9.jpg", width: 5, height: 3 },
  { src: "/Images/p_10.jpg", width: 4, height: 5 },
  { src: "/Images/p_11.jpg", width: 6, height: 3 }
];

const objects = [
  { src: "/Images/o_1.jpg", width: 9, height: 4 },
  { src: "/Images/o_2.jpg", width: 9, height: 6 },
  { src: "/Images/o_3.jpg", width: 6, height: 4 },
  { src: "/Images/o_4.jpg", width: 7, height: 4 },
  { src: "/Images/o_5.jpg", width: 5, height: 4 },
  { src: "/Images/o_6.jpg", width: 4, height: 3 }
];
