import React from "react";
import "./styles.css";
import { SocialIcon } from "react-social-icons";

export default function Footer() {
  return (
    <div className="Footer">
      
      <SocialIcon
        url="https://www.instagram.com/sivangallry/"
        className="FooterIcons"
      />
      <p className="FooterItems">
        @2024 copy rights - www.sivangallery.com
      </p>
    </div>
  );
}
