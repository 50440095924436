import React from "react";
import "./styles.css";
import { Route, NavLink, Link, BrowserRouter as Router } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

export default function NavBar() {
  return (
    <div>
     

<div className="NavBar">
<div className="logo-block">
        <img className="logo-style" src="/Images/logo.png"></img>
      </div>
      <div className="menuVisible">
        <NavLink to="/" className="NavItems" activeClassName="active">
          Home
        </NavLink>
        <NavLink to="/about" className="NavItems" activeClassName="active">
          About
        </NavLink>
        <NavLink to="/gallery" className="NavItems" activeClassName="active">
          Gallery
        </NavLink>
        <NavLink to="/contact" className="NavItems" activeClassName="active">
          Contact
        </NavLink>
        <NavLink to="/ongoing" className="NavItems" activeClassName="active">
          OnGoing...
        </NavLink>
      </div>
      <div className="burgerVisible">
        <Menu>
          <NavLink to="/" className="menu-items" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/about" className="menu-items" activeClassName="active">
            About
          </NavLink>
          <NavLink to="/gallery" className="menu-items" activeClassName="active">
            Gallery
          </NavLink>
          <NavLink to="/contact" className="menu-items" activeClassName="active">
            Contact
          </NavLink>
          <NavLink to="/ongoing" className="menu-items" activeClassName="active">
            OnGoing...
          </NavLink>
        </Menu>
      </div>
    </div>

    </div>
    
  );
}
