import React from "react";
import "./styles.css";
import FadeIn from "react-fade-in";

export default function Contact() {
  return (
    <FadeIn>
      <div className="homeContainer">
        <h1 className="contact">Meet me @</h1>
        <h2 className="contact">+91 9962 914 280 / +91 9063 936 008</h2>
        <div className="contact contact-address">
          <div className="contact-address-box">
            <div>
              <h3>Tamilnadu</h3>
            </div>
            4B2, 
            Rams Ragasree Villas,
            Perunthalaivar Kamarajar Nagar Salai, 
            Perungudi, Chennai, TN, India
            - 600096
          </div>
          <div className="contact-address-box">
          <div>
              <h3>Andhrapradesh</h3>
            </div>
            7-6-921/A, 
            1st lane, Vengalarao nagar,
            Near Lurtheran high school, 
            Guntur, AP, India
            - 522002
          </div>
        </div>
      </div>
    </FadeIn>
  );
}
